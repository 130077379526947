import React, { FunctionComponent, Fragment } from 'react';
import { getImage, getSrc } from 'gatsby-plugin-image';

import {
  AuthorPageQuery,
  ArticlePreviewFragment,
} from '../../../generated/graphql-types';
import Navbar from '../Navbar';
import {
  AuthorPageHeader,
  AuthorSummaryContainer,
  AuthorSummaryImage,
  AuthorSummaryContent,
  AuthorSummaryName,
  AuthorSummaryTitle,
  AuthorSummaryBio,
  ArticlesContainer,
} from './AuthorPage.style';
import ArticlePreviewList from '../ArticlePreviewList';
import Layout from '../Layout';
import ArticleHighlight from '../ArticleHighlight';
import Divider from '../Divider';
import SEOTags from '../SEOTags';

interface AuthorPageProps {
  data: AuthorPageQuery;
}

const AuthorPage: FunctionComponent<AuthorPageProps> = ({ data }) => {
  const highlightedArticle: ArticlePreviewFragment | undefined =
    data.highlightedArticle.nodes[0];
  const allArticles = highlightedArticle
    ? data.allArticles.nodes.filter((n) => n.slug !== highlightedArticle.slug)
    : data.allArticles.nodes;
  const author = data.author;
  const siteMetadata = data.site?.siteMetadata;
  const seoImage = author?.imagePreview && getSrc(author.imagePreview as any);

  return (
    <Layout>
      <SEOTags
        title={`${author?.name} | ${siteMetadata?.title}`}
        description={author?.shortBio?.shortBio || undefined}
        canonical={`${siteMetadata?.siteUrl}/author/${author?.slug}/index.html`}
        openGraph={{
          title: `${author?.name} | ${siteMetadata?.title}`,
          description: author?.shortBio?.shortBio || undefined,
          type: 'website',
          url: `${siteMetadata?.siteUrl}/author/${author?.slug}`,
          images: seoImage
            ? [
                {
                  url: seoImage,
                  alt: author?.name || 'Author',
                  width: 600,
                  height: 600,
                },
              ]
            : [],
        }}
      />
      <AuthorPageHeader>
        <Navbar overlay />
        <AuthorSummaryContainer>
          <AuthorSummaryImage image={getImage(author?.image as any) as any} alt={author?.name ?? 'Author'}/>
          <AuthorSummaryContent>
            <AuthorSummaryName>{author?.name}</AuthorSummaryName>
            <AuthorSummaryTitle>{author?.title}</AuthorSummaryTitle>
            <AuthorSummaryBio
              dangerouslySetInnerHTML={{
                __html: author?.shortBio?.childMarkdownRemark?.html as string,
              }}
            />
          </AuthorSummaryContent>
        </AuthorSummaryContainer>
      </AuthorPageHeader>
      <ArticlesContainer>
        <main>
          {highlightedArticle && (
            <Fragment>
              <ArticleHighlight article={highlightedArticle} />
              {allArticles.length > 0 && <Divider />}
            </Fragment>
          )}
          <ArticlePreviewList articles={allArticles} />
        </main>
      </ArticlesContainer>
    </Layout>
  );
};

export default AuthorPage;
