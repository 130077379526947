import styled from '@emotion/styled';
import { GatsbyImage } from 'gatsby-plugin-image';

import { colours, shadows, spacing } from '../../style/variables';
import { cover, breakpoint } from '../../style/utils';
import pattern from '../../assets/specks.png';
import Container from '../Container';

export const AuthorPageHeader = styled.header`
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 440px;
  background-color: ${colours.grey.lightest};
  box-shadow: ${shadows.header};

  &:after {
    content: "";
    ${cover};
    background-image: url("${pattern}");
    background-size: 600px;
    background-repeat: repeat;
    opacity: 0.08;
    z-index: 0;
    pointer-events: none;
  }
`;

const summaryVerticalBreakpoint = breakpoint({ max: 'md' });

export const AuthorSummaryContainer = styled(Container)`
  padding-top: ${48 + spacing.navbarHeight}px;
  padding-bottom: 60px;
  display: flex;
  flex-wrap: wrap;
  flex: 1 0 auto;
  font-size: 60px;

  @media ${summaryVerticalBreakpoint} {
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding-top: ${36 + spacing.navbarHeight}px;
    padding-bottom: 52px;
  }
  @media ${breakpoint({ max: 'xs' })} {
    font-size: 44px;
    padding-top: ${24 + spacing.navbarHeight}px;
    padding-bottom: 36px;
  }
`;

export const AuthorSummaryImage = styled(GatsbyImage)`
  width: 200px;
  height: 200px;
  margin-right: 48px;
  border-radius: 50%;
  flex: 0 0 auto;

  @media ${summaryVerticalBreakpoint} {
    width: 160px;
    height: 160px;
    margin-right: 0;
    margin-bottom: 20px;
  }
  @media ${breakpoint({ max: 'xs' })} {
    width: 120px;
    height: 120px;
  }
`;

export const AuthorSummaryContent = styled.div`
  flex: 1 0 0;
`;

export const AuthorSummaryName = styled.h1`
  font-size: 0.9em;
  margin-top: 0;
  margin-bottom: 8px;
`;

export const AuthorSummaryTitle = styled.h2`
  font-size: 0.6em;
  margin-top: 0;
  margin-bottom: 16px;
`;

export const AuthorSummaryBio = styled.div`
  font-size: 0.4em;
  margin-top: 0;
  margin-bottom: 0;
`;

export const ArticlesContainer = styled(Container)`
  padding-top: 100px;
  padding-bottom: 120px;

  @media ${breakpoint({ max: 'xs' })} {
    padding-top: 60px;
    padding-bottom: 80px;
  }
`;
